//global styles

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.App {
  height: 100%;
}

:root {
  --primaryBrand: #ffc600;
  --primarySoft: #ffb980;
  --primaryBrandLight: #ffeee0;
  --primaryWhite: #fff9f5;
  --darkGray: #aeaeae;
  --gray: #e5e5e5;
  --silver: #f6f6f6;
  --dark: #404042;
  --white: #ffffff;
  --error: #f91e1e;
}

h1 {
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 3.375rem;
  text-align: center;
  vertical-align: bottom;
  letter-spacing: -0.03rem;
  color: #404042;
}

h2 {
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.25rem;
  letter-spacing: -0.03rem;
  margin-bottom: 1rem;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #404042;
}

::-webkit-input-placeholder {
  color: #aeaeae;
}

:-ms-input-placeholder {
  color: #aeaeae;
}

::placeholder {
  color: #aeaeae;
}

//override style for custom slider

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

.slider {
  width: 100%;
  height: 0.5rem;
  background: #e5e5e5;
  outline: none;
  -webkit-transition: 0.2s;
}

.slider-dot {
  width: 2rem;
  height: 2rem;
  border: 0.5rem solid #ffc600;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.rc-slider-handle {
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.rc-slider-tooltip-content {
  margin-bottom: 2.5rem !important;
}

.rc-slider-tooltip-arrow {
  border: 0 !important;
  display: none !important;
}

.rc-slider-tooltip-inner {
  background-color: transparent !important;
  color: #404042 !important;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 2.25rem !important;
  line-height: 3.375rem !important;
  box-shadow: none !important;
}

.rc-slider-tooltip-placement-top {
  padding: 0 !important;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

//calendar style

.react-calendar__month-view__weekdays {
  font-family: 'Open Sanse', sans-serif;
  font-weight: bold;
  text-decoration: none !important;
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
  color: #404042;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__tile:hover {
  background-color: #f7f7f7;
  border-radius: 50%;
}

.react-calendar__tile--active {
  border-radius: 50%;
  background: #ffc600 !important;
  font-weight: bold;
  color: white !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #e5e5e5 !important;
}

.react-calendar__month-view__weekdays__weekday {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #404042;
  text-align: center;
  margin-bottom: 1.2rem;
}

.react-calendar__navigation__label {
  background: #ffc600;
  border-radius: 16px;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: white;
  padding: 0.25rem 1.5rem 0.25rem 1.5rem;
  cursor: pointer;
  border: none;
}

.react-calendar__navigation {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-evenly;
}

.react-calendar__navigation__arrow {
  background-color: white !important;
  border: none;
  cursor: pointer;
  &:disabled {
    visibility: hidden;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__prev2-button {
  display: none;
}

.rc-slider-dot {
  bottom: -20px !important;
  margin-left: 0 !important;
  width: 2px !important;
  height: 8px !important;
  background: #e5e5e5 !important;
  border: none !important;
  border-radius: 0 !important;
  cursor: default !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
